import axios from 'axios'
import FileSaver from 'file-saver'
import {
    START_LOADING,
    STOP_LOADING,
    SET_VENDOR_LIST,
    SET_VENDOR_COLUMNS
} from '../reducers/types'
import {success, error} from '../components/common/toast'
import {ROOT_URL} from './config'

export function getAllVendors() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Retrieving Vendor List'})
        return axios({
            method: 'GET',
            url: `${ROOT_URL}/vendor/all`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Vendor List'}))
        .then(res => {
            dispatch({type: SET_VENDOR_LIST, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function updateVendor(vendor) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Vendor Details'})
        return axios({
            method: 'PUT',
            url: `${ROOT_URL}/vendor/edit`,
            data: vendor
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Vendor Details'}))
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getVendorColumns(vendorId) {
    return function(dispatch) {
        dispatch({type: SET_VENDOR_COLUMNS, payload: null})
        if (!vendorId) return
        dispatch({type: START_LOADING, payload: 'Retrieving Vendor Feed Columns'})
        return axios({
            method: 'GET',
            url: `${ROOT_URL}/vendor/${vendorId}/columns`
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Retrieving Vendor Feed Columns'}))
        .then(res => {
            dispatch({type: SET_VENDOR_COLUMNS, payload: res.data});
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function setVendorColumns(vendorId, columns) {
    return function(dispatch) {
        dispatch({type: SET_VENDOR_COLUMNS, payload: null})
        dispatch({type: START_LOADING, payload: 'Uploading Vendor Feed Columns'})
        return axios({
            method: 'PUT',
            url: `${ROOT_URL}/vendor/${vendorId}/columns`,
            data: columns
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Uploading Vendor Feed Columns'}))
        .then(res => {
            getVendorColumns(vendorId)
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function checkVendorInventory() {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Generating Inventory File'})
        return axios({
            method: 'GET',
            url: `${ROOT_URL}/vendor/check`,
            responseType: 'arraybuffer'
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Generating Inventory File'}))
        .then(res => {
            let filename = null;
            const disposition = res.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            FileSaver.saveAs(new Blob([res.data], {type: res.headers['content-type']}), filename || "Dialog Vendor Inventory.xlsx");
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function classifyVendorStock(vendorId, buffer) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Loading Vendor Inventory'})
        return axios({
            method: 'POST',
            url: `${ROOT_URL}/vendor/check/${vendorId}`,
            data: buffer,
            headers: {'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
            responseType: 'arraybuffer'
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Loading Vendor Inventory'}))
        .then(res => {
            let filename = null;
            const disposition = res.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            FileSaver.saveAs(new Blob([res.data], {type: res.headers['content-type']}), filename || "Dialog Vendor Inventory.xlsx");
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function updateVendorInventory(vendorId, stones) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Updating Vendor Inventory'})
        return axios({
            method: 'PUT',
            url: `${ROOT_URL}/vendor/${vendorId}/inventory`,
            data: stones
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Updating Vendor Inventory'}))
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}

export function getVendorReport(vendorId) {
    return function(dispatch) {
        dispatch({type: START_LOADING, payload: 'Generating Vendor Report'})
        return axios({
            method: 'GET',
            url: `${ROOT_URL}/reports/vendor/${vendorId}`,
            responseType: 'arraybuffer'
        })
        .finally(() => dispatch({type: STOP_LOADING, payload: 'Generating Vendor Report'}))
        .then(res => {
            let filename = null;
            const disposition = res.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
            }
            FileSaver.saveAs(new Blob([res.data], {type: res.headers['content-type']}), filename || "Dialog Vendor.xlsx");
        })
        .catch(err => {
            error(err)
            return Promise.reject(err);
        })
    }
}