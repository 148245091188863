export const SET_USER = 'SET_USER'
export const SET_AUTH_TIME = 'SET_AUTH_TIME'
export const LOG_OUT = 'LOG_OUT'
export const SET_CUSTOMER = 'SET_CUSTOMER'

export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const CLEAR_LOADING = 'CLEAR_LOADING'

export const ADD_STONE_TAB = 'ADD_STONE_TAB'
export const CLOSE_TAB = 'CLOSE_TAB'
export const SELECT_TAB = 'SELECT_TAB'
export const SELECT_EMBEDDED_TAB = 'SELECT_EMBEDDED_TAB'

export const SAVE_FILTERS = 'SAVE_FILTERS'

//export const ALL_INVENTORY = 'ALL_INVENTORY'
export const SET_AVAILABLE = 'SET_AVAILABLE'
export const SET_MY_INVENTORY = 'SET_MY_INVENTORY'
export const SET_PAST_INVENTORY = 'SET_PAST_INVENTORY'
export const SET_CART = 'SET_CART'
export const ADD_DETAILS = 'ADD_DETAILS'

export const SET_AVAILABLE_J = 'SET_AVAILABLE_J'
export const SET_MY_J = 'SET_MY_J'
export const SET_PAST_J = 'SET_PAST_J'
export const SET_CART_J = 'SET_CART_J'
export const SET_J_MODELS = 'SET_J_MODELS'
export const SET_ALL_J_MODELS = 'SET_ALL_J_MODELS'
export const ADD_DETAILS_J = 'ADD_DETAILS_J'
export const SET_MODEL_REQUESTS = 'SET_MODEL_REQUESTS'

export const SET_AVAILABLE_LG = 'SET_AVAILABLE_LG'
export const SET_MY_LG = 'SET_MY_LG'
export const SET_PAST_LG = 'SET_PAST_LG'
export const SET_CART_LG = 'SET_CART_LG'
export const ADD_DETAILS_LG = 'ADD_DETAILS_LG'

export const SET_CART_DESIRES = 'SET_CART_DESIRES'
export const SET_OPEN_DESIRES = 'SET_OPEN_DESIRES'
export const SET_CLOSED_DESIRES = 'SET_CLOSED_DESIRES'
export const ADD_DESIRE_PRICE = 'ADD_DESIRE_PRICE'

export const SET_ALL_ATTRIBUTES = 'SET_ALL_ATTRIBUTES'

export const SET_CUSTOMER_LIST = 'SET_CUSTOMER_LIST'
export const SET_USER_LIST = 'SET_USER_LIST'
export const SET_AVAILABLE_ACCOUNTS = 'SET_AVAILABLE_ACCOUNTS'

export const GET_MONTH_BILLS = 'GET_MONTH_BILLS'

export const SET_STORES = 'SET_STORES'
export const SET_COL_MAP = 'SET_COL_MAP'
export const SET_VAL_MAP = 'SET_VAL_MAP'
export const SET_BIN_TEMPLATES = 'SET_BIN_TEMPLATES'
export const SET_IDEAL_BINS = 'SET_IDEAL_BINS'
export const CLEAR_IDEAL_BINS = 'CLEAR_IDEAL_BINS'
export const SET_REASSORT_RESULTS = 'SET_REASSORT_RESULTS'
export const SET_ASSET_PAGES = 'SET_ASSET_PAGES'

export const SET_VENDOR_LIST = 'SET_VENDOR_LIST'
export const SET_VENDOR_COLUMNS = 'SET_VENDOR_COLUMNS'
