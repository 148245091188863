import React, {useEffect, useState} from "react";
import {connect} from 'react-redux'
import * as actions from '../../actions'
import { parseVendorStock, promiseReadBinaryFile } from './stoneReader'
import { success, error } from "../common/toast";
import NumberFormat from "react-number-format";

function VendorDetails({vendor, getAllVendors, updateVendor, vendorColumns, getVendorColumns, setVendorColumns, classifyVendorStock, updateVendorInventory, getVendorReport, ...props}) {
    const [vendorStockFile, setVendorStockFile] = useState(null)
    const [vendorStockAnswers, setVendorStockAnswers] = useState(null)
    const handleFileChange = (e) => {
        const target = e.currentTarget || e.target
        if (target.name === 'vendorStockFile') {
            if (target.files.length && target.files[0]) setVendorStockFile(target.files[0])
            else setVendorStockFile(null)
        } else if (target.name === 'vendorStockAnswers') {
            if (target.files.length && target.files[0]) setVendorStockAnswers(target.files[0])
            else setVendorStockAnswers(null)
        }
    }

    async function readVendorStock() {
        try {
            const excelBuffer = promiseReadBinaryFile(vendorStockFile)
            await classifyVendorStock(vendor.vendorId, vendorStockFile)
        } catch (err) {
            console.error(err)
            error(err?.message ?? err)
        }
    }

    async function readVendorAnswers() {
        try {
            const stones = await parseVendorStock(vendorStockAnswers)
            const toUpdate = stones.filter(s => s.vendorName === vendor.name).map(({parcelName, blackList, whiteList, comments}) => ({vendorId: vendor.vendorId, vendorStockId: parcelName, blackList, whiteList, comments}))
            await updateVendorInventory(vendor.vendorId, toUpdate)
            setVendorStockFile({target: {name: 'vendorStockAnswers'}})
            success('Update successful.')
        } catch (err) {
            console.error(err)
            error(err?.message ?? err)
        }
    }

    const [stateVendor, setStateVendor] = useState({...vendor})
    useEffect(() => {
        setStateVendor(vendor ? {...vendor} : {})
        getVendorColumns(vendor.vendorId)
    }, [vendor])
    function handleChange(e) {
        const target = e.currentTarget || e.target
        setStateVendor({...stateVendor, [target.name]: target.value})
    }
    function handleCheckbox(e) {
        const target = e.currentTarget || e.target
        setStateVendor({...stateVendor, [target.name]: target.checked})
    }

    const [stateColumns, setStateColumns] = useState(vendorColumns?.map(c => ({...c})))
    useEffect(() => {
        setStateColumns(vendorColumns?.map(c => ({...c})))
    }, [vendorColumns])
    function handleColumnChange(e) {
        const target = e.currentTarget || e.target
        const i = target.getAttribute('idx')
        const col = stateColumns[i]
        setStateColumns(stateColumns.toSpliced(i, 1, {...col, [target.name]: target.value}))
    }
    function handleAddColumn() {
        setStateColumns([...stateColumns, {vendorId: vendor.vendorId}])
    }
    function handleRemoveColumn(i) {
        setStateColumns(stateColumns.toSpliced(i,1))
    }

    function handleCancel() {
        setStateVendor({...vendor})
        setStateColumns(vendorColumns?.map(c => ({...c})))
    }
    async function handleSave() {
        console.log('stateVendor', stateVendor)
        console.log('stateColumns', stateColumns)
        await updateVendor(stateVendor)
        await setVendorColumns(vendor.vendorId, stateColumns)
        success('Vendor Data Saved')
        getAllVendors()
        getVendorColumns(vendor.vendorId)
    }

    function downloadReport() {
        getVendorReport(vendor?.vendorId)
    }

    function renderColumns() {
        if (!stateColumns?.length) return 'No Columns defined for this Vendor'
        else return stateColumns.map((col, i) => (
            <div className='row' key={col.vendorColumnId ?? (i+1000000)}>
                <div className='inline-col'>
                    Column Header&nbsp;
                    <input name='vendorColumnHeader' idx={i} onChange={handleColumnChange} value={col?.vendorColumnHeader ?? ''} />
                </div>
                <div className='inline-col'>
                    Attribute&nbsp;
                    <select name='attribute' idx={i} onChange={handleColumnChange} value={col?.attribute ?? ''} >
                        <option value=''></option>
                        {allowedColumns.map(c => (
                            <option key={c.value} value={c.value}>{c.name}</option>
                        ))}
                    </select>
                </div>
                <div className='inline-col clickable-icon'>
                    <i className="fas fa-minus-circle" onClick={() => handleRemoveColumn(i)} />
                </div>
            </div>
        ))
    }

    return <div>
        <div className='row spaced'>
            <div>Upload Stock Excel</div>
            <input name='vendorStockFile' type='file' onChange={handleFileChange} disabled={!props.isAdmin} value={vendorStockFile==null ? '' : undefined} />
            <button className='btn' onClick={readVendorStock} disabled={!vendorStockFile || !props.isAdmin}>Read and Sort</button>
        </div>
        <div className='row spaced'>
            <div>Upload Accept/Reject</div>
            <input name='vendorStockAnswers' type='file' onChange={handleFileChange} disabled={!props.isAdmin} value={vendorStockAnswers==null ? '' : undefined} />
            <button className='btn' onClick={readVendorAnswers} disabled={!vendorStockAnswers || !props.isAdmin}>Read and Save Stones</button>
        </div>
        <div className='row spaced'>
            24 Hour Report
            &nbsp;
            <button className='btn' onClick={downloadReport}>Download</button>
        </div>
        <div className='row spaced'>&nbsp;</div>
        <div className='row spaced'>
            Vendor Details
            &emsp;
            <button className='btn btn-secondary' onClick={handleCancel}>Cancel</button>
            &nbsp;
            <button className='btn' onClick={handleSave}>Save</button>
        </div>
        <div className='row spaced caption25'>
            <div>Name</div>
            <div>
                <input name='name' onChange={handleChange} value={stateVendor.name || ''} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>Legal Name</div>
            <div>
                <input name='legalName' onChange={handleChange} value={stateVendor.legalName || ''} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>Email</div>
            <div>
                <input name='email' onChange={handleChange} value={stateVendor.email || ''} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>Tier</div>
            <div>
                <input name='tier' onChange={handleChange} value={stateVendor.tier || ''} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>DX Prefix</div>
            <div>
                <input name='dxPrefix' onChange={handleChange} value={stateVendor.dxPrefix || ''} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>Our Cost % to Deploy</div>
            <div>
                <NumberFormat name='ourDeploymentCost' display='input' onChange={handleChange} value={stateVendor.ourDeploymentCost ?? ''} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>Vendor's Share of Fees</div>
            <div>
                <NumberFormat name='feeShare' display='input' onChange={handleChange} value={stateVendor.feeShare ?? ''} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>Vendor's Share of Rebates</div>
            <div>
                <NumberFormat name='rebateShare' display='input' onChange={handleChange} value={stateVendor.rebateShare ?? ''} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>Our Cost % when Sold</div>
            <div>
                <NumberFormat name='saleShare' display='input' onChange={handleChange} value={stateVendor.saleShare ?? ''} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>Markup</div>
            <div>
                <NumberFormat name='markup' display='input' onChange={handleChange} value={stateVendor.markup ?? ''} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>Notify of Requests</div>
            <div>
                <input type='checkbox' name='sendRequests' onChange={handleCheckbox} checked={stateVendor.sendRequests || false} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>Notify of Returns</div>
            <div>
                <input type='checkbox' name='sendReturns' onChange={handleCheckbox} checked={stateVendor.sendReturns || false} />
            </div>
        </div>
        <div className='row spaced caption25'>
            <div>Notify of Sales</div>
            <div>
                <input type='checkbox' name='sendSales' onChange={handleCheckbox} checked={stateVendor.sendSales || false} />
            </div>
        </div>
        <div className='row spaced'>
            <div className='row'>Feed Columns&nbsp;<button onClick={handleAddColumn}>New</button></div>
            {renderColumns()}
        </div>
    </div>
}

function mapStateToProps(state) {
    return {
        isAdmin: Boolean(state.user?.currentUser?.administrator),
        vendorColumns: state.admin?.vendorColumns
    }
}

export default connect(mapStateToProps, actions)(VendorDetails)

const allowedColumns = [
    {value: 'parcelName', name: 'Parcel Name'},
    {value: 'lab', name: 'Lab'},
    {value: 'certNo', name: 'Cert #'},
    {value: 'weight', name: 'Weight'},
    {value: 'shapeCode', name: 'Shape'},
    {value: 'colour', name: 'Colour'},
    {value: 'clarity', name: 'Clarity'},
    {value: 'fluorescence', name: 'Fluorescence'},
    {value: 'cut', name: 'Cut'},
    {value: 'polish', name: 'Polish'},
    {value: 'symmetry', name: 'Symmetry'},
    {value: 'measurements', name: 'Measurements'},
    {value: 'tablePct', name: 'Table %'},
    {value: 'depthPct', name: 'Depth %'},
    {value: 'crownHeight', name: 'Crown Height'},
    {value: 'crownAngle', name: 'Crown Angle'},
    {value: 'pavilionDepth', name: 'Pavilion Depth'},
    {value: 'pavilionAngle', name: 'Pavilion Angle'},
    {value: 'girdlePct', name: 'Girdle %'},
    {value: 'starHalves', name: 'Star Halves'},
    {value: 'pavilionHalves', name: 'Lower Half'},
    {value: 'lightPerformance', name: 'AGS Light Performance'},
    {value: 'photoUrl', name: 'Video URL'},
    {value: 'photoUrl2', name: 'Photo URL'},
    {value: 'countryOfOrigin', name: 'Country of Origin'},
    {value: 'vendorPrice', name: 'Vendor Total Price'},
    {value: 'keyToSymbols', name: 'Key to Symbols'}
]