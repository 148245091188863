import React, { useEffect, useMemo, useState } from "react";
import {connect} from 'react-redux'
import * as actions from '../../actions'
import VendorDetails from './vendorDetails'

function VendorList({vendors, checkVendorInventory, getAllVendors, ...props}) {
    const [selectedVendorId, setSelectedVendorId] = useState(null)
    const selectedVendor = useMemo(() => (vendors || []).find(v => v.vendorId == selectedVendorId), [vendors, selectedVendorId])
    function selectVendor(e) {
        const target = e.currentTarget || e.target
        setSelectedVendorId(target.value)
    }

    useEffect(() => {
        getAllVendors()
    }, [])

    return <div>
        <div className='row spaced'>
            <button onClick={() => checkVendorInventory()}>Download All Vendor Inventory</button>
        </div>
        <div className='row spaced'>
            <select value={selectedVendorId} onChange={selectVendor}>
                <option value=''></option>
                {(vendors || []).map(vendor => <option value={vendor.vendorId}>{`${vendor.vendorId} | ${vendor.name}`}</option>)}
            </select>
        </div>
        <div className='row spaced'>&nbsp;</div>
        {selectedVendor ? <VendorDetails vendor={selectedVendor}/> : null}
    </div>
}

function mapStateToProps(state) {
    return {
        isAdmin: Boolean(state.user?.currentUser?.administrator),
        vendors: state.admin?.vendorList
    }
}

export default connect(mapStateToProps, actions)(VendorList)