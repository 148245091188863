const Excel = require('exceljs')

export async function promiseReadBinaryFile(f) {
    return new Promise((resolve, reject) => {
        const fr = new FileReader()
        fr.onloadend = () => resolve(fr.result)
        fr.onabort = reject
        fr.onerror = reject
        fr.readAsArrayBuffer(f)
    })
}

export async function parseVendorStock(file) {
    const buffer = await promiseReadBinaryFile(file)
    const book = new Excel.Workbook()
    await book.xlsx.load(buffer)

    const parsedStones = []
    book.eachSheet(sheet => {
        const header = {}
        let headerRow = null
        sheet.eachRow((row, rowNum) => {
            if (header.vendor >= 0) return
            const parsedHeaders = {}
            row.eachCell((cell, colNum) => {
                // console.log('cell', rowNum, colNum, cell.value)
                try {
                    if (cell.value?.toLowerCase()==='vendor') parsedHeaders.vendor = colNum
                    if (cell.value?.toLowerCase()==='parcel name') parsedHeaders.parcelName = colNum
                    if (cell.value?.toLowerCase()==='new status') parsedHeaders.newStatus = colNum
                    if (cell.value?.toLowerCase()==='comments') parsedHeaders.comments = colNum
                } catch(err) {}
            })
            // if (rowNum<3) console.log('parsedHeaders', parsedHeaders)
            if (Object.keys(parsedHeaders).length >= 4) {
                Object.assign(header, parsedHeaders)
                headerRow = rowNum
            }
            // console.log('header', header)
        })
        if (!header.parcelName) return
        sheet.eachRow((row, rowNum) => {
            if (rowNum <= headerRow) return
            const stone = {
                vendorName: row.getCell(header.vendor).value,
                parcelName: row.getCell(header.parcelName).value,
                comments: readValue(row.getCell(header.comments)),
                whiteList: readValue(row.getCell(header.newStatus)) ==='Accept',
                blackList: readValue(row.getCell(header.newStatus)) ==='Reject'
            }
            if (stone.vendorName && stone.parcelName) parsedStones.push(stone)
        })
    })
    if (!parsedStones?.length) throw new Error('Either the required columns were not found in this excel, or there were no stones to read.')
    return parsedStones
}

function readValue(cell) {
    if (cell.value?.formula || cell.value?.sharedFormula) return cell.value?.result
    else return cell.value
}