import axios from 'axios'
import Cookies from 'js-cookie'
import {store} from '../store/index'
import {
    CLEAR_LOADING,
    SET_AUTH_TIME,
    LOG_OUT
} from '../reducers/types'

axios.interceptors.request.use(function(config) {
    const token = Cookies.get('session');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    const state = store.getState().user;
    if (state && state.currentUser && (state.currentUser.administrator || state.currentUser.salesRep) && state.customer && !config.headers['X-DIALOG-CUSTOMER']) {
        config.headers['X-DIALOG-CUSTOMER'] = state.customer.customerId;
    }
    return config;
})

axios.interceptors.response.use(
    function(response) {
        store.dispatch({type: SET_AUTH_TIME});
        return response;
    },
    function(error) {
        if (!error.response || error.response.status == 401) {
            store.dispatch({type: LOG_OUT});
        }
        return Promise.reject(error);
    }
)

export * from './user'
export * from './tabs'
export * from './inventory'
export * from './jewellery'
export * from './labGrown'
export * from './attributes'
export * from './admin'
export * from './billing'
export * from './vendor'

export function clearLoading() {
    return function(dispatch) {
        dispatch({type: CLEAR_LOADING})
    }
}